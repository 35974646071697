import Heading from "@/components/Heading";
import { PreviewContent } from "@/customTypes/preview";
import Query500 from "@/queries/Query500.graphql";
import { useAtom } from "jotai";
import craft from "lib/craft";
import { GetStaticProps } from "next";
import Head from "next/head";
import React, { useEffect } from "react";

export const getStaticProps: GetStaticProps = async ({
  params,
  previewData: nextPreviewData,
  preview,
}) => {
  // For preview capability
  const previewData: PreviewContent = nextPreviewData as PreviewContent;

  const response = await craft(
    Query500,
    {},
    preview ? previewData.token : null
  );

  const { primaryNavigation, footerNavigation } = response;

  return {
    props: {
      navigation: {
        primaryNavigation,
        footerNavigation,
      },
    },
    revalidate: 3600,
  };
};

const NotFound = ({ pageEntry }) => {
  return (
    <div className="py-48">
      <Head>
        <title>500 An Error Occurred</title>
      </Head>
      <Heading heading="500 An Error Occurred" />
    </div>
  );
};

export default NotFound;
