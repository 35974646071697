import HeadingTag from "@/components/HeadingTag";
import clsx from "clsx";
import React from "react";
import { useInView } from "react-intersection-observer";
import nl2br from "react-nl2br";

const Heading = ({
  heading = null,
  subHeading = null,
  theme = "dark",
  target = null,
}) => {
  const { ref, inView } = useInView();

  return (
    <div
      ref={ref}
      className={clsx("delay-250 transform transition duration-1000 ease-out")}
    >
      {!!heading && (
        <div className={clsx("m-auto")}>
          <HeadingTag heading={heading} theme={theme} centered />
        </div>
      )}
      {subHeading && (
        <div
          className={clsx(
            "m-auto mt-4 max-w-2xl",
            "text-center text-xl font-bold tracking-tight lg:text-2xl"
          )}
        >
          {nl2br(subHeading)}
        </div>
      )}
    </div>
  );
};

export default Heading;
