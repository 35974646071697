import clsx from "clsx";
import React from "react";

const HeadingTag = ({ heading, centered = true, tag = "h2" }) => {
  const Wrapper = ({ children, className }) => {
    switch (tag) {
      case "h1": {
        return <h1 className={className}>{children}</h1>;
      }
      case "h2": {
        return <h2 className={className}>{children}</h2>;
      }
      case "h3": {
        return <h3 className={className}>{children}</h3>;
      }
      default: {
        return <h2 className={className}>{children}</h2>;
      }
    }
  };

  return (
    <Wrapper
      className={clsx(
        "max-w-4xl",
        "font-bold tracking-tight",
        "text-3xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl",

        { "m-auto text-center": centered }
      )}
    >
      {heading}
    </Wrapper>
  );
};

export default HeadingTag;
